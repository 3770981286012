var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("router-link", { attrs: { id: "primary-logo", to: "/" } }, [
    _c("img", {
      attrs: { alt: `${_vm.siteName} Home Page`, src: _vm.logoSrc },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }